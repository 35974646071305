#slideLeft {
  border: transparent;
  z-index: 999;
  margin-left: -8px;
  position: absolute;
  display: flex;
  padding: 0px 10px;
  color: #beb7c2;
  margin-top: 3px;
  background: linear-gradient(
    to left,
    hsla(0, 0%, 100%, 0.367) 50%,
    transparent
  );
  width: 41px;
  height: 20px;
}
.disabled-link {
  cursor: not-allowed;
  opacity: 0.5; /* Adjust the opacity to visually indicate the disabled state */
}

#slideRight {
  border: transparent;
  position: fixed;
  right: 23px;
  color: #beb7c2;
  font-weight: 900;
  background: linear-gradient(
    to right,
    hsla(0, 0%, 100%, 0.367) 50%,
    transparent
  );
  font-size: 22px;
}
.tablist {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  display: flex;
  overflow-x: scroll;
}
.global-search-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
#tablist::-webkit-scrollbar {
  width: 0px;
}
.client-list-wrapper {
  max-width: 100vw;
  overflow: auto;
}
.client-list-wrapper .MuiTableContainer-root {
  height: calc(100vh - 280px) !important;
}
.action-list-wrapper {
  max-width: 100vw;
  overflow: auto;
}
.action-list-wrapper .MuiTableContainer-root {
  height: calc(100vh - 380px) !important;
}
.content-data {
  width: 1400px;
  /* overflow: auto; */
}

.page-header h2 {
  font-size: 16px;
  margin: 6px;
}
.form-container {
  flex: 1;
  width: calc(100vw - 175px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.admin-form-container {
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.form-container::-webkit-scrollbar {
  display: none;
}
.form-container h5 {
  margin-top: 5px;
  font-size: 14px;
  padding: 14px;
}
.grid-container,
.admin-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-gap: 30px; */
  gap: 5px 10px;
}

.admin-container {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* text-align: center; */
  padding: 5px 0;
  /* font-size: 12px; */
}

.sidenav-history {
  overflow: auto;
  transition: 0.5s;
  margin: 0 10px;
}

.sidenav-history a {
  padding: 8px 8px 10px 8px;
  text-decoration: none;
  font-size: 12px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav-history a.active {
  color: #0f6cbd;
  font-weight: bold;
}
.sidenav-history a:hover {
  color: #0f6cbd;
  font-weight: bold;
  background-color: #f9f9f9;
}

.sidenav {
  overflow: auto;
  transition: 0.5s;
  height: calc(100vh - 250px);
  position: fixed;
  margin: 0 10px;
}
.sidenav a {
  padding: 8px 8px 10px 8px;
  text-decoration: none;
  font-size: 12px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a.active {
  color: #0f6cbd;
  font-weight: bold;
}
.sidenav a:hover {
  color: #0f6cbd;
  font-weight: bold;
  background-color: #f9f9f9;
}
.common-drawer {
  top: 130px !important;
  /* padding: 5px !important; */
}

.tab-menubar {
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-menubar::-webkit-scrollbar {
  display: none;
  width: 0;
}

.disable,
.disable:hover {
  color: #bdbdbd !important;
  cursor: not-allowed !important;
}

.MuiTableRow-root.Mui-selected:hover {
  z-index: 1 !important;
}

.drawerHead {
  padding: 2px 4px !important;
}
.drawerBody {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}
.drawer-content-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
}
.label {
  font-weight: 600;
}
.send_mail_popup {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}
.message_body {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}
.message_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
}

.cl-layout {
  display: flex;
  height: calc(100vh - 300px);
}
.cl-sidebar {
  width: 120px;
  /* background-color: #2c3e50; */
  /* color: white; */
  padding: 12px;
  box-sizing: border-box;
}
.cl-content {
  flex-grow: 1;
  padding: 15px;
  box-sizing: border-box;
}
.cl-breadcrumbs {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 5px;
}

.cl-breadcrumbs ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.cl-breadcrumbs li {
  display: flex;
  align-items: center;
}

.cl-breadcrumbs li a {
  text-decoration: none;
  color: #3498db;
}

.cl-breadcrumbs li a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.cl-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 8px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 0.6rem;
}

.cl-page-header h1 {
  margin: 0;
  font-size: 24px;
}
.cl-breadcrumbs ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.cl-breadcrumbs li {
  display: flex;
  align-items: center;
}

.cl-separator {
  margin: 0 0.5rem;
}
/* .cl-page-header .breadcrumbs {
  margin-top: 10px;
}
.cl-separator {
  margin: 0 5px;
  color: #6c757d; 
} */
.page-top-area-section {
  margin-top: 2px;
}
.global-search-filter {
  display: flex;
  gap: 8px; /* Spacing between filter elements */
  align-items: center;
}

.add-client-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 16px;
}
