.email-template {
  background-color: #f0f0f0;
  /* height: calc(100vh - 285px); */
  height: 140px;
  width: 100%;
  padding: 10px;
  display: grid;
  /* flex-direction: row; */
  /* gap: 50px; */
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
}
.email-template-add {
  background-color: #f0f0f0;
  /* height: calc(100vh - 285px); */
  height: 300px;
  width: 100%;
  padding: 10px;
  display: grid;
  /* flex-direction: row; */
  /* gap: 50px; */
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
}
.email-template-add-popup {
  background-color: #f0f0f0;
  height: 320px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input-width-popup {
  max-width: 100%;
  min-width: 100%;
}
.input-width {
  max-width: 350px;
  min-width: 350px;
}

.fui-Input.r1oeeo9n {
  border-radius: 0 !important;
}
.flex-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  width: 350px;
  /* column-gap: 39px; */
  background-color: aqua;
  border: 1px solid #c0c0c0;
  background-color: #ffff;
  border-bottom: 0.5px solid #00000099;
  cursor: pointer;
}
.flex-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65px;
  width: 100%;
  /* column-gap: 39px; */
  background-color: aqua;
  border: 1px solid #c0c0c0;
  background-color: #ffff;
  border-bottom: 0.5px solid #00000099;
  cursor: pointer;
}
.file-name {
  display: flex;
  justify-content: center;
  width: fit-content;
}
.action-button {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notification {
  display: flex;
  height: calc(100vh - 295px);
  align-items: center;
  justify-content: center;
}
.notification-font {
  font-size: 17px;
  font-weight: 600;
  color: red;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.section-container {
  display: flex;
  gap: 25px;
  flex-direction: column;
}
.view-bold {
  font-size: 14px;
  font-weight: 600;
}
.list-upload-items {
  position: relative;
  list-style-type: none;
  font-weight: 600;
}

.file-drops {
  display: flex;
  align-items: center;
  gap: 15px;
}
