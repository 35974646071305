.layout-body-wrapper {
  display: grid;
  grid-template-columns: 1fr 20fr;
}

.layout-body-content-wrapper {
  min-height: calc(100vh - 250px);
}

.main-body-content-section {
  margin: 40px 0;
}
/* .global-search-filter {
  display: flex;
  gap: 10px;
  padding: 20px 0;
} */

.side-menu-global {
  cursor: pointer;
  margin: 5px 0;
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.side-menu-global-active {
  background-color: rgba(25, 118, 210, 0.11);
}
.breadcrumbs-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.sideNavBody :focus {
  outline: none;
  box-shadow: none;
}
.client_container_flex {
  height: calc(100vh - 205px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.client_income_outgoings_flex {
  height: calc(100vh - 375px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
