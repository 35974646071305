@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}
body,
html {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  height: 100%;
}
.MuiTableRow-root.Mui-selected:not(:last-child) {
  background-color: #f0f0f0 !important;
}

.MuiTableRow-root.Mui-selected:hover .css-1x2a0ic-MuiTableCell-root {
  z-index: 3 !important;
  background-color: #c5d8ea !important;
}
.MuiTableRow-root.Mui-selected .css-1x2a0ic-MuiTableCell-root {
  z-index: 3 !important;
  background-color: #f0f0f0 !important;
}
