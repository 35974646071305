.headings{
    font-size: 16px;
    font-weight: 700;
}
.description{
    font-size: 13px;
}
.sub-heading{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.tab-section{
    padding: 8px;
}
.buttons{
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    flex-direction: row;
}
.tab-heading{
    display: grid;
    grid-template-columns: 2.5fr 3.2fr 3.5fr;
}
.bolds{
    font-size: 14px;
    font-weight: 700;
}
.tab-content{
        display: grid;
            grid-template-columns:2.7fr 4.3fr 4fr;
            padding-inline: 20px;
}
.accordion-section{
    min-height: 280px;
    max-height: 380px;
    overflow: auto;
}
.s-a-section{
    height: 262px;
    width: 200px;
    border: 1px solid #C0C0C0;
    overflow: auto;

}
.s-a-section-a{
    height: 262px;
    width: 200px;
    border: 1px solid #C0C0C0;
    overflow: auto;
    /* padding: 18px; */
}
.sub-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
}
.s-b-section{
    height: 262px;
    width: 550px;
        border: 1px solid #C0C0C0;
overflow: scroll;


}
.sec-a-b{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.sec{
    display: flex;
    flex-direction: column;
}
.line{
    width: 100%;

border-bottom: 1px solid #C0C0C0;}
.accordionDots{
    padding-inline: 20px;
}
.title-section{
    font-size: 14px;
    font-weight: 700;
}
.main-sub-title{
    font-size: 14px;
    font-weight: 700;
}
.not-use{
    font-size: 12px;
}
.tab-sub-title{
display: flex;
flex-direction: column;
line-height:14px;
}
.border-section{
    border: 2px solid #aed6f1;
}
.border-inner-style{
    padding: 3px;
}
.split-btn-section{
    /* height: 250px; */
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.btn-section{

    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    height: 250px;
    display: flex;
    justify-content: center;
}
.title-sections{
    font-size: 16px;
    font-weight: 700;
}
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    min-height: 100px; 
  }
  .spinner-container-tree {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%; 
    min-height: 200px; 
  }

