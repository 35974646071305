.main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: #f0f0f0; */
  height: calc(100vh - 205px);
  width: 100%;
  padding: 15px 0;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 8px;
}
.fui-Button {
  border-radius: 0;
}
.send_mail_section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 10px;
}
